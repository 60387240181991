import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";


@Injectable()
export class DeviceService {
  constructor(private http: HttpClient) {}

  add(form: { deviceToken: string, platform: string }) {
    return this.http.post('/device/add-device', form).pipe(
      map(data => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  remove(form: { deviceToken: string }) {
    return this.http.post('/device/remove-device', form).pipe(
      map(data => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }
}
