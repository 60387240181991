import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject, Observable, of } from 'rxjs';
import { concatMap, tap, mapTo } from 'rxjs/operators';

interface Message {
  message: string;
  option: string;
  config: MatSnackBarConfig;
}

@Component({
  selector: 'mat-snackbar',
  templateUrl: 'mat-snackbar.component.html',
})
export class MatSnackbarComponent {
  public errorMessage = '';
  private emitError = new Subject<Message>();
  private snackBar$: Observable<Message>;

  constructor(private _snackBar: MatSnackBar) {
    this.snackBar$ = this.emitError.pipe(
      concatMap((message) => this.getSnackBarDelay(message)),
      tap((message) =>
        this._snackBar.open(message.message, message.option, message.config)
      )
    );

    this.snackBar$.subscribe();
  }

  public open(message: string, option: string = 'OK', config: MatSnackBarConfig = {
    duration: 5000,
    panelClass: ['style-green'],
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
  }) {
    this.emitError.next({
      message: message, option: option, config: config
    });

    return this._snackBar._openedSnackBarRef;
  }

  private getSnackBarDelay(message: Message) {
    const snackbarRef = this._snackBar._openedSnackBarRef;
    if (!!snackbarRef) {
      return snackbarRef!.afterDismissed().pipe(mapTo(message));
    } else {
      return of(message);
    }
  }
}
