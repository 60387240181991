export const environment = {
  production: true,
  apiURL: 'https://api.event-suite.com',
  portApi: '80',
  gmapApiKey: 'AIzaSyCPb1qXzqa9VZI18QL7EuhU2evqX0meZSg',
  stripeKey: 'pk_live_51LjlpJDVtRLNPKPmxIBJWW8VoKUdUf2m8LZvDfBwFlsgsFRFKeaQXsMhL4Pr2bRhTcAa2iGD0E5dVYhprNdVE5mI00jEHbxvzZ',
  firebaseConfig: {
    apiKey: "AIzaSyCPb1qXzqa9VZI18QL7EuhU2evqX0meZSg",
    authDomain: "event-suite-g4fjb4.firebaseapp.com",
    projectId: "event-suite-g4fjb4",
    storageBucket: "event-suite-g4fjb4.appspot.com",
    messagingSenderId: "1095819331424",
    appId: "1:1095819331424:web:3f6ef33c3c95c8a557c005",
    measurementId: "G-E8808BSHZK"
  },
};
