import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { JwtAuthService } from "./auth/jwt-auth.service";
import { NotificationConfirmationModelComponent } from "app/views/app-dialogs/notifications-confirmation/notifications-confirmation.component";
import { DeviceService } from "./device.service";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { MatSnackbarComponent } from "app/shared/components/mat-snackbar/mat-snackbar.component";
declare var Notification: any;

@Injectable()
export class MessagingService {
    constructor(
        private dialog: MatDialog,
        private angularFireMessaging: AngularFireMessaging,
        private jwtAuth: JwtAuthService,
        private cookieService: CookieService,
        private _snackbar: MatSnackbarComponent,
        private deviceService: DeviceService
    ) { }

    public async init() {
        await this.requestPermission();
        this.receiveMessage();
    }

    async requestPermission(): Promise<void> {
        let currentToken: string | null = null;

        this.angularFireMessaging.getToken.subscribe({
            next: (token) => {
                currentToken = token;
            },
            error: (error) => {
                console.error('Error fetching current token:', error);
            }
        });

        if (Notification && Notification.permission !== "granted" && this.jwtAuth.isLoggedIn()) {
            this.angularFireMessaging.requestToken.subscribe({
                next: (newToken) => {
                    this.jwtAuth.profile$.subscribe({
                        next: (profile) => {
                            if (Notification.permission === "denied") {
                                console.error("Notification permission was blocked by the user.");
                                return;
                            }
                            if (!newToken) {
                                console.error("An error occured while generating the FCM token. Is your browser supported?")
                                return;
                            }
                            if (currentToken && currentToken !== newToken || this.cookieService.get("fcm_token")) {
                                let tokenToRemove = currentToken || this.cookieService.get("fcm_token");
                                this.deviceService.remove({
                                    deviceToken: tokenToRemove
                                }).subscribe({
                                    next: () => {
                                    },
                                    error: (error) => {
                                        console.error('Error removing old token:', error);
                                    }
                                });
                            }

                            if (!profile.devices.includes(newToken)) {
                                let modalRef = this.dialog.open(NotificationConfirmationModelComponent, {
                                    width: '75vw',
                                });

                                modalRef.afterClosed().subscribe({
                                    next: (value) => {
                                        if (!value) return;
                                        this.cookieService.set("fcm_token", newToken, 365);
                                        this.deviceService.add({
                                            deviceToken: newToken,
                                            platform: 'web'
                                        }).subscribe({
                                            next: (result) => {
                                                if (result.error) {
                                                    console.error(result.error.error);
                                                    return;
                                                }
                                            },
                                            error: (error) => {
                                                console.error('Error adding new device:', error);
                                            }
                                        });
                                    },
                                    error: (error) => {
                                        console.error('Error in modal closing:', error);
                                    }
                                });
                            }
                        },
                        error: (error) => {
                            console.error('Error fetching user profile:', error);
                        }
                    });
                },
                error: (error) => {
                    console.error('Error requesting new token:', error);
                }
            });
        }
    }

    receiveMessage(): void {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                console.log('Message received:', payload);
                this._snackbar.open(`${payload.notification.title}\n${payload.notification.body}`, 'Close', {
                    duration: 15000
                })
            }
        );
    }
}
