import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeService } from './services/theme.service';
import { NavigationService } from './services/navigation.service';
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './guards/auth.guard';
import { UserRoleGuard } from './guards/user-role.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { DirectAccessGuard } from './guards/direct.guard';
import { ContactService } from './services/api/contact.service';
import { DeepLinkService } from './services/deep-link.service';
import { ResultsService } from './services/api/events/results.service';

@NgModule({
  imports: [CommonModule, SharedComponentsModule, SharedPipesModule, SharedDirectivesModule],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    DeepLinkService,
    ResultsService,
    AuthGuard,
    DirectAccessGuard,
    UserRoleGuard,
    ContactService,
    AppConfirmService,
    AppLoaderService
  ],
  exports: [SharedComponentsModule, SharedPipesModule, SharedDirectivesModule],
})
export class SharedModule {}
