import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  release: "1.0.31",
  dsn: 'https://e4d1670d47eb4a98b10efd79b477b0db@sentry.intercube.cloud/5', // Replace with your actual Sentry DSN
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: ['localhost', 'https://event-suite.com', 'https://event-suite.co.uk', 'https://api.event-suite.com', /^\//],
  tracesSampleRate: 1.0, // Adjust the sample rate as needed
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
