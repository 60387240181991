import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AppLayoutComponent } from './shared/components/layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { LandingLayoutComponent } from './shared/components/layouts/landing-layout/landing-layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LandingLayoutComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => import('./views/landing/landing.module').then((m) => m.LandingModule),
        data: { title: 'Landing' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./views/login/login.module').then((m) => m.LoginModule),
        data: { title: 'Login' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'post-payment',
        loadChildren: () => import('./views/post-payment/post-payment.module').then((m) => m.PostPaymentModule),
        data: { title: 'Payment Received' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'post-registration',
        loadChildren: () =>
          import('./views/post-registration/post-registration.module').then((m) => m.PostRegistrationModule),
        data: { title: 'Registration Received' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'register',
        loadChildren: () => import('./views/register/register.module').then((m) => m.RegisterModule),
        data: { title: 'Register' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'privacy-policy',
        loadChildren: () => import('./views/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
        data: { title: 'Privacy Policy' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./views/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
        data: { title: 'Forgot Password' },
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'password-reset',
        loadChildren: () => import('./views/password-reset/password-reset.module').then((m) => m.PasswordResetModule),
        data: { title: 'Reset Password' },
      },
    ],
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' },
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then((m) => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'PROFILE' },
      },
      {
        path: 'locations',
        loadChildren: () => import('./views/locations/locations.module').then((m) => m.LocationsModule),
        data: { title: 'Locations', breadcrumb: 'LOCATIONS' },
      },
      {
        path: 'membership',
        loadChildren: () => import('./views/membership/membership.module').then((m) => m.MembershipModule),
        data: { title: 'Memberships', breadcrumb: 'MEMBERSHIPS' },
      },
      {
        path: 'events',
        loadChildren: () => import('./views/events/events.module').then((m) => m.EventsModule),
        data: { title: 'Events', breadcrumb: 'EVENTS' },
      },
      {
        path: 'shop',
        loadChildren: () => import('./views/shop/shop.module').then((m) => m.ShopModule),
        data: { title: 'Shop', breadcrumb: 'SHOP' },
      },
      {
        path: 'results',
        loadChildren: () => import('./views/results/results.module').then((m) => m.ResultsModule),
        data: { title: 'Results', breadcrumb: 'RESULTS' },
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then((m) => m.UsersModule),
        data: { title: 'Users', breadcrumb: 'USERS' },
      },
      {
        path: 'governing-body',
        loadChildren: () => import('./views/governing-body/governing-body.module').then((m) => m.GoverningBodyModule),
        data: { title: 'Governing Type', breadcrumb: 'GOVERNING BODY' },
      },
    ],
  },
  {
    path: 'error',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/error/error.module').then((m) => m.ErrorModule),
        data: { title: 'Error', breadcrumb: 'Error' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];
