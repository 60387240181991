import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications-confirmation-model',
  templateUrl: './notifications-confirmation.model.html',
  styleUrls: ['./notifications-confirmation.model.scss']
})
export class NotificationConfirmationModelComponent {

  constructor(
    public dialogRef: MatDialogRef<NotificationConfirmationModelComponent>,
  ) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
