import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { initialMenu } from '../inmemory-db/navigation';

export interface IMenuItem {
  type: string;
  name?: string;
  state?: string;
  icon?: string;
  svgIcon?: string;
  tooltip?: string;
  disabled?: boolean;
  sub?: Array<IChildItem>;
  badges?: Array<IBadge>;
  role?: Array<string>;
}
interface IChildItem {
  type?: string;
  name: string;
  state?: string;
  icon?: string;
  svgIcon?: string;
  sub?: Array<IChildItem>;
  role?: Array<string>;
}

interface IBadge {
  color: string;
  value: string;
}

@Injectable()
export class NavigationService {
  iconTypeMenuTitle = 'Frequently Accessed';

  menuItems = new BehaviorSubject<Array<IMenuItem>>(initialMenu);

  menuItems$ = this.menuItems.asObservable();

  constructor() {
  }


  publishNavigationChange(menuType: string) {
    this.menuItems.next(initialMenu);
  }
}
