import { Injectable } from '@angular/core';

declare let window: any;

interface RouteEntry {
    pattern: RegExp;
    generateUrl: (id?: string) => string | null;
}

const routeMapping: RouteEntry[] = [
    {
        pattern: /\/shop\/products\/(\d+)/,
        generateUrl: (id) => id ? `event-suite://event-suite.com/event?eventId=${id}` : null,
    },
    {
        pattern: /\/shop/,
        generateUrl: () => `event-suite://event-suite.com/shop`,
    },
    {
        pattern: /\/login/,
        generateUrl: () => `event-suite://event-suite.com/signIn`,
    },
    {
        pattern: /\/register/,
        generateUrl: () => `event-suite://event-suite.com/signUp`,
    },
    {
        pattern: /\landing/,
        generateUrl: () => `event-suite://event-suite.com/`,
    },
    {
        pattern: /\profile/,
        generateUrl: () => `event-suite://event-suite.com/profile`,
    },
    {
        pattern: /\results/,
        generateUrl: () => `event-suite://event-suite.com/results`,
    },
];

@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {

    getMobileOperatingSystem(): string {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) return "windows phone";
        if (/android/i.test(userAgent)) return "android";
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "ios";
        return "unknown";
    }

    handleDeepLinks(id: string | null, currentUrl: string): void {
        const os = this.getMobileOperatingSystem();
        let generateUrl: ((id?: string) => string | null) | undefined;

        for (const entry of routeMapping) {
            if (entry.pattern.test(currentUrl)) {
                generateUrl = entry.generateUrl;
                break;
            }
        }

        if (os === "android" || os === "ios") {
            if (generateUrl) {
                const url = generateUrl(id);
                if (url) {
                    window.location.href = url;
                } else {
                    console.log('Generated URL is null for route:', currentUrl);
                }
            } else {
                console.log('No matching route found for:', currentUrl);
            }
        }
    }

    extractIdFromUrl(currentUrl: string): string | null {
        for (const entry of routeMapping) {
            const match = currentUrl.match(entry.pattern);
            if (match) {
                return match[1];
            }
        }
        return null;
    }
}