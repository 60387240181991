import { config } from 'config';
import { IMenuItem } from '../services/navigation.service';

export const initialMenu: Array<IMenuItem> = [
  {
    name: 'Dashboard',
    type: 'link',
    tooltip: 'Dashboard',
    icon: 'dashboard',
    state: 'dashboard',
    role: config.authRoles.rider,
  },
  {
    name: 'Shop',
    type: 'link',
    tooltip: 'Shop',
    icon: 'shop',
    state: 'shop',
    role: config.authRoles.user,
  },
  {
    name: 'Locations',
    type: 'link',
    tooltip: 'Locations',
    icon: 'location_on',
    state: 'locations/list',
    role: config.authRoles.sa,
  },
  {
    name: 'Governing Bodies',
    type: 'link',
    tooltip: 'Governing Bodies',
    icon: 'badge',
    state: 'governing-body/list',
    role: config.authRoles.sa,
  },
  {
    name: 'Events',
    type: 'dropDown',
    tooltip: 'Events',
    icon: 'event',
    state: 'events',
    role: config.authRoles.organiser,
    sub: [
      { name: 'My Events', state: '/my-events', role: config.authRoles.organiser },
      { name: 'Event Durations', state: '/duration/list', role: config.authRoles.sa },
      { name: 'Unapproved Events', state: '/unapproved/list', role: config.authRoles.sa },
      { name: 'Disciplines', state: '/disciplines/list', role: config.authRoles.sa },
      { name: 'Result Type', state: '/result-type/list', role: config.authRoles.sa },
    ],
  },
  {
    name: 'Membership',
    type: 'dropDown',
    tooltip: 'Membership',
    icon: 'card_membership',
    state: '/membership',
    role: config.authRoles.organiser,
    sub: [
      { name: 'My Memberships', state: '/my-memberships', role: config.authRoles.organiser },
      { name: 'All Memberships', state: '/list', role: config.authRoles.sa },
      { name: 'Create Membership', state: '/new', role: config.authRoles.organiser },
    ],
  },
  {
    name: 'Results',
    type: 'link',
    tooltip: 'Results',
    icon: 'leaderboard',
    state: 'results/list',
    role: config.authRoles.user,
  },
  {
    name: 'Users',
    type: 'link',
    tooltip: 'Users',
    icon: 'supervisor_account',
    state: 'users/list',
    role: config.authRoles.sa,
  },
  {
    name: 'Sign out',
    type: 'link',
    tooltip: 'Sign out',
    icon: 'exit_to_app',
    state: 'login/signout',
    role: config.authRoles.user,
  },
];
