export const config = {
  defaultCurrency: 'GBP',
  defaultCurrencyPrefix: '£',
  vat: false,
  vatValue: 20,
  authRoles: {
    sa: ['ROLE_SUPER_ADMIN'],
    admin: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    official: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFICIAL'],
    organiser: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFICIAL', 'ROLE_ORGANISER'],
    helper: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFICIAL', 'ROLE_ORGANISER', 'ROLE_HELPER'],
    rider: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFICIAL', 'ROLE_ORGANISER', 'ROLE_HELPER', 'ROLE_RIDER'],
    spectator: [
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_OFFICIAL',
      'ROLE_ORGANISER',
      'ROLE_HELPER',
      'ROLE_RIDER',
      'ROLE_SPECTATOR',
    ],
    user: [
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_OFFICIAL',
      'ROLE_ORGANISER',
      'ROLE_HELPER',
      'ROLE_RIDER',
      'ROLE_SPECTATOR',
      'ROLE_USER',
    ],
  },
};
