<div class="sidebar-panel">
    <div id="scroll-area" class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">

            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/logo.png" alt="" class="app-logo" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                <!--<span class="app-logo-text">EGRET</span>-->

                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle"
                    (click)="toggleSidenav()" matTooltip="Hide Navigation">
                    <!--<mat-icon>menu</mat-icon>-->
                    <mat-icon>{{layoutConf.sidebarStyle === 'closed' ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}}</mat-icon>
                </button>
            </div>
            <!-- Sidebar user -->
            <!--<div class="app-user">
                <div class="app-user-photo">
                <img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="">
                </div>
                <span class="app-user-name mb-8">
                <mat-icon class="icon-xs text-muted">lock</mat-icon>
                {{jwtAuth?.user?.first_name}} {{jwtAuth?.user?.last_name}}
                </span>
                <div class="app-user-controls">
                <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                [matMenuTriggerFor]="appUserMenu">
                    <mat-icon>settings</mat-icon>
                </button>
                <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Inbox"
                routerLink="/inbox">
                  <mat-icon>email</mat-icon>
                </button>
                <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Sign Out"
                onclick="jwtAuth.signOut();">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
                <mat-menu #appUserMenu="matMenu">
                    <button mat-menu-item routerLink="/profile">
                        <mat-icon>account_box</mat-icon>
                        <span>Profile</span>
                    </button>
                    <button mat-menu-item routerLink="/profile/settings">
                        <mat-icon>settings</mat-icon>
                        <span>Account Settings</span>
                    </button>
                    <button mat-menu-item routerLink="/calendar">
                        <mat-icon>date_range</mat-icon>
                        <span>Calendar</span>
                    </button>
                    <button mat-menu-item (click)="handleLogout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sign out</span>
                    </button>
                    </mat-menu>
                </div>
            </div>-->
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle">
            </app-sidenav>
        </div>
    </div>
</div>