import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { mergeMap, tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AppConfig } from 'app/app.config';
import { JwtAuthService } from '../services/api/auth/jwt-auth.service';
import { MatSnackbarComponent } from '../components/mat-snackbar/mat-snackbar.component';
import { AppLoaderService } from '../services/app-loader/app-loader.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  config: { remote: string, remoteNonApi: string, portApi: string, production: boolean, headerName: string, authScheme: string, throwNoTokenError: boolean, skipWhenExpired: boolean };

  constructor(
    appConfig: AppConfig,
    private appLoaderService: AppLoaderService,
    private jwtAuth: JwtAuthService,
    private location: Location,
    private snack: MatSnackbarComponent
  ) {
    this.config = appConfig.getConfig();
  }

  handleInterception(token: string | null, request: HttpRequest<any>, next: HttpHandler) {
    //this.appLoaderService.open();
    let tokenIsExpired = false;
    request = request.clone({
      headers: request.headers
        .set('Cache-Control', 'no-cache, no-store, must-revalidate')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('If-Modified-Since', '0'),
    });

    if (!token && this.config.throwNoTokenError) {
      throw new Error('Could not get token from function.');
    }

    if (this.config.skipWhenExpired) {
      tokenIsExpired = token ? this.jwtAuth.isTokenExpired(token) : true;
    }

    if (token && tokenIsExpired && this.config.skipWhenExpired) {
      request = request.clone();
    } else if (token && !request.url.includes('login_check')) {
      token = token.replace(/^"(.*)"$/, '$1');
      request = request.clone({
        setHeaders: {
          [this.config.headerName]: `${this.config.authScheme}${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (data) => {
          //this.appLoaderService.close();
          if (data instanceof HttpResponse) {
            if (data.url.includes(this.config.remote) && data.status === 200 && !this.config.production) {
              console.log('[' + request.method + '] Request Intercepted', data.url, data.body);
            }
          }
        },
        async (err: any) => {
          //this.appLoaderService.close();
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 403:
                this.snack.open('Going Back one page, insufficient permissions for current page.', 'OK');
                this.location.back();
                break;
              default:
                return this;
            }
          }
        }
      )
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('i18n') && !req.url.includes('assets') && !req.url.startsWith('http')) {
      req = req.clone({ url: this.config.remote + req.url });
    }

    const token: any = this.jwtAuth.getJwtToken();

    if (token instanceof Promise) {
      return from(token).pipe(
        mergeMap((asyncToken: string | null) => {
          return this.handleInterception(asyncToken, req, next);
        })
      );
    } else {
      return this.handleInterception(token, req, next);
    }
  }
}
