import { Component, OnInit, Input } from '@angular/core';
import { UserRoleGuard } from 'app/shared/guards/user-role.guard';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
})
export class SidenavComponent implements OnInit {
  @Input() public items: Array<any> = [];
  @Input() public hasIconMenu: boolean;
  @Input() public iconMenuTitle: string;

  constructor(private appGuard: UserRoleGuard) {}
  ngOnInit() {}

  addMenuItem() {
    this.items.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        { name: 'SUBITEM', state: 'cards' },
        { name: 'SUBITEM', state: 'buttons' },
      ],
    });
  }
}
