import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Result } from "app/shared/models/api/result.model";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class ResultsService {
  constructor(private http: HttpClient) {}

  list(): Observable<any> {
    return this.http.get(`/results/list`).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  get(subEventId: number): Observable<Array<Result>> {
    return this.http.get(`/results/${subEventId}`).pipe(
      map((data: Array<Result>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  create(subEventId: number, jsonPayload: unknown): Observable<Array<Result>> {
    return this.http.post(`/sub-event/results/${subEventId}/submit`, jsonPayload).pipe(
      map((data: Array<Result>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  delete(subEventId: number): Observable<Array<unknown>> {
    return this.http.post(`/sub-event/results/${subEventId}`, []).pipe(
      map((data: Array<unknown>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }
}
