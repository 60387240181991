import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  NgZone,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import hljs, { HighlightResult } from 'highlight.js';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appEgretHighlight]',
})
export class EgretHighlightDirective implements OnInit, OnChanges, OnDestroy {
  highlightedCode: string;
  @HostBinding('class.hljs') hljs = true;
  @HostBinding('innerHTML') innerHTML = 'highlightedCode';
  @Input() path: string;
  @Input('appEgretHighlight') code: string;
  private unsubscribeAll: Subject<any>;
  @Input() languages: Array<string>;

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef, private _zone: NgZone, private http: HttpClient) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    if (this.code) {
      this.highlightElement(this.code);
    }
    if (this.path) {
      this.highlightedCode = 'Loading...';
      this.http
        .get(this.path, { responseType: 'text' })
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((response) => {
          this.highlightElement(response, this.languages);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next(undefined);
    this.unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['code'] &&
      changes['code'].currentValue &&
      changes['code'].currentValue !== changes['code'].previousValue
    ) {
      this.highlightElement(this.code);
    }
  }

  highlightElement(code: string, languages?: Array<string>) {
    this._zone.runOutsideAngular(() => {
      let result: HighlightResult;
      if (languages && languages.length > 0) {
        // Try highlighting with provided languages
        for (const lang of languages) {
          try {
            result = hljs.highlight(code, { language: lang });
            break;
          } catch (e) {
            // Continue if an error occurs for this language
            continue;
          }
        }
      }
      if (!result) {
        // Fallback to auto-detection if no language match
        result = hljs.highlightAuto(code);
      }
      this.highlightedCode = result.value;
    });
  }
}
