import {
  Component,
  OnInit,
  HostListener,
} from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.template.html',
})
export class LandingLayoutComponent implements OnInit {
  public isModuleLoading = false;
  public scrollConfig = {};
  public layoutConf: any = {};
  public adminContainerClasses: any = {};

  constructor(
    public themeService: ThemeService,
    private layout: LayoutService
  ) {
    /*const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');*/
  }

  ngOnInit() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout.adjustLayout(event);
  }
}
