import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({ selector: 'img' })
export class LazyImgDirective {
  private placeholderDiv: HTMLElement;

  constructor(
    private el: ElementRef,
    { nativeElement }: ElementRef<HTMLImageElement>,
    private renderer: Renderer2
  ) {
    const supports = 'loading' in HTMLImageElement.prototype;
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');

    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }
  }

  ngOnInit() {
    if (this.el.nativeElement.className.includes('app-logo')) {
      return;
    }

    this.createPlaceholder();
  }

  private createPlaceholder() {
    this.placeholderDiv = this.renderer.createElement('div');
    this.renderer.addClass(this.placeholderDiv, 'image-loader');

    let loaderSpan = this.renderer.createElement('span');
    this.renderer.addClass(loaderSpan, 'loader');

    this.renderer.appendChild(this.placeholderDiv, loaderSpan);
  
    this.renderer.insertBefore(this.el.nativeElement.parentNode, this.placeholderDiv, this.el.nativeElement);
  }

  @HostListener('load')
  onLoad() {
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    if (this.placeholderDiv) {
      this.renderer.removeChild(this.el.nativeElement.parentNode, this.placeholderDiv);
    }
  }
}