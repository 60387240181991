import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/api/auth/jwt-auth.service';
import { MatSnackbarComponent } from '../components/mat-snackbar/mat-snackbar.component';

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(private jwtAuth: JwtAuthService, private snack: MatSnackbarComponent) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.hasAccess(route.data.role, true);
  }

  onFail(showSnack: boolean): boolean {
    if (showSnack) {
      this.snack.open('You do not have access to this page!');
    }

    return false;
  }

  public hasAccess(requiredRole: any, showSnack = false): boolean {
    const userRoles: Array<string> = this.jwtAuth.getDecodedToken().roles;
    if ((userRoles === undefined || userRoles.length === 0)) {
      return this.onFail(showSnack);
    }
    const hasRole: Array<string> = requiredRole.filter((element: string) => userRoles.includes(element));
    if (hasRole === undefined || hasRole.length === 0) {
      return this.onFail(showSnack);
    }

    return true;
  }
}
