import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganiserStatistics } from 'app/shared/models/api/statistics/organiser-statistics.model';
import { HostEvent } from 'app/shared/models/api/host-event.model';
import { StripeOrganiserHolder } from 'app/shared/models/api/stripe-organiser.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class OrganiserService {
  constructor(private http: HttpClient) {}

  getStatistics(): Observable<OrganiserStatistics> {
    return this.http.get(`/organiser/statistics`).pipe(
      map((data: OrganiserStatistics) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  financeStats(): Observable<any> {
    return this.http.get(`/organiser/finance-stats`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  setup(): Observable<StripeOrganiserHolder> {
    return this.http.get(`/organiser/setup`).pipe(
      map((data: StripeOrganiserHolder) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  myEvents(): Observable<Array<HostEvent>> {
    return this.http.get(`/organiser/my-events`).pipe(
      map((data: Array<HostEvent>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  refundByPaymentIntent(json: {amount: number, paymentIntent: string}) {
    return this.http.post(`/stripe/refund`, json).pipe(
      map((data: Array<HostEvent>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  email(form: { recipientIds: Array<number>, subject: string, body: string, subEventIds: Array<number>}) {
    return this.http.post(`/organiser/send-email`, form).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  payouts() {
    return this.http.get(`/organiser/payout`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  payout(amount: string, instant: boolean = false) {
    return this.http.post(`/organiser/payout`, {amount, instant}).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }
}
