import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class MembershipService {
  constructor(private http: HttpClient) { }

  get(membershipId: number): Observable<any> {
    return this.http.get(`/membership-discount/${membershipId}`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getInfo(membershipId: number): Observable<any> {
    return this.http.get(`/membership-discount/${membershipId}/info`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getUserMembership(): Observable<Array<any>> {
    return this.http.get(`/membership-discount/user/active`).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getMembershipUserById(userId: number): Observable<Array<any>> {
    return this.http.get(`/membership-discount/user/${userId}/active`).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getOwn(): Observable<any> {
    return this.http.get(`/organiser/my-memberships/`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getEntries(membershipId: number): Observable<any> {
    return this.http.get(`/organiser/my-memberships/${membershipId}/list`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getAll(): Observable<any> {
    return this.http.get(`/membership-discount/`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getActive(): Observable<any> {
    return this.http.get(`/membership-discount/active`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  create(data: unknown): Observable<any> {
    return this.http.post(`/membership-discount/new`, data).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  uploadImage(id: number, data: any) {
    return this.http.post(`/membership-discount/${id}/upload-picture`, data).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  addRider(data: any): Observable<any> {
    return this.http.post(`/membership-discount/${data.membershipId}/add-member`, { membershipNumber: data.membershipNumber }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }
}
