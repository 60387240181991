import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeepLinkService } from 'app/shared/services/deep-link.service';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  private routerEventSub: Subscription;

  constructor(private deepLinkService: DeepLinkService, private router: Router) {
    this.routerEventSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange: NavigationEnd) => {
        const currentUrl = routeChange.url;
        const id = this.deepLinkService.extractIdFromUrl(currentUrl);

        // Call handleDeepLinks with the extracted ID
        this.deepLinkService.handleDeepLinks(id, currentUrl);
      });
    /*const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');*/
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }
}
