<ng-container *ngIf="hostEvent && data && results; then content; else spinner;">
</ng-container>
<ng-template #content>
    <h1 mat-dialog-title>Results for {{hostEvent.eventName}} - {{data.resultData.subEventName}}</h1>
    <div mat-dialog-content *ngIf="results.length > 0">
        <div class="results-top-bar">
            <mat-form-field appearance="fill" [style.width.px]=350>
                <mat-label>Search for rider</mat-label>
                <input matInput type="text" [(ngModel)]="filterTerm" name="searchProduct" class="default-bg my-1"
                    cdkFocusInitial />
            </mat-form-field>
        </div>
        <mat-table [dataSource]="results | filter : filterTerm" class="my-3">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Rider </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="name">
                    {{ element.firstName | titlecase }} {{ element.lastName | titlecase }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef> Position </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="position">
                    {{ element.position }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef> Time </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="time">
                    {{ element.time | timeFormat }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="points">
                <mat-header-cell *matHeaderCellDef> Points </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="points">
                    {{ element.points }}
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay"> </mat-row>
        </mat-table>
    </div>
    <div mat-dialog-content
        *ngIf="!results || results.length <= 0 || (memberships | filter: filterTerm) && (memberships | filter: filterTerm).length === 0">
        <h2>No Results Found.</h2>
    </div>
</ng-template>
<ng-template #spinner>
    <div class="modal-loader">
        <span class="loader"></span>
    </div>
</ng-template>
<div mat-dialog-actions *ngIf="hostEvent && data">
    <button mat-button color="purple2" (click)="onCancel()">Cancel</button>
    <button mat-button color="green" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>