import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HostEvent } from "app/shared/models/api/host-event.model";
import { HostEventStatistics } from "app/shared/models/api/statistics/host-event-statistics.model";
import { SubEventStatistics } from "app/shared/models/api/statistics/sub-event-statistics.model";
import { UnapprovedEvent } from "app/shared/models/api/unapproved-event.model";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class EventService {
  constructor(private http: HttpClient) {}

  getAll(currentPage: number = 1): Observable<Array<HostEvent>> {
    return this.http.get(`/host-event/?page=${currentPage}`).pipe(
      map((data: Array<HostEvent>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  getAllUnapproved(): Observable<Array<UnapprovedEvent>> {
    return this.http.get(`/host-event/pending-approval`).pipe(
      map((data: Array<UnapprovedEvent>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  approve(event: UnapprovedEvent): Observable<any> {
    return this.http.get(`/host-event/approve/${event.id}`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  decline(event: UnapprovedEvent, reason: any): Observable<any> {
    return this.http.post(`/host-event/deny/${event.id}`, reason).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  get(eventId: number): Observable<HostEvent> {
    return this.http.get(`/host-event/${eventId}`).pipe(
      map((data: HostEvent) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  create(jsonPayload): Observable<any> {
    // TODO: add type
    return this.http.post(`/host-event/new`, jsonPayload).pipe(
      map((data) => {
        // TODO: add type
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  edit(eventId: number, formData: any): Observable<any> {
    // TODO: add type
    return this.http.post(`/host-event/${eventId}/edit`, formData).pipe(
      map((data) => {
        // TODO: add type
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  delete(event: HostEvent): Observable<any> {
    // TODO: add type
    return this.http.delete(`/host-event/${event.id}`).pipe(
      map((data) => {
        // TODO: add type
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  getHostEventStatistics(hostEventId: number): Observable<HostEventStatistics> {
    return this.http.get(`/host-event/${hostEventId}/statistics`).pipe(
      map((data: HostEventStatistics) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  getSubEventStatistics(subEventId: number): Observable<SubEventStatistics> {
    return this.http.get(`/sub-event/${subEventId}/stats`).pipe(
      map((data: SubEventStatistics) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  getSubEventEntrants(subEventId: number): Observable<any> {
    return this.http.get(`/sub-event/${subEventId}/entrants`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }
}
