import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import * as _moment from 'moment';
import { UserService } from '../user.service';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'app/shared/models/api/user.model';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token: string;
  decodedToken: any;
  decodedToken$: BehaviorSubject<any>;
  signingIn: boolean;
  JWT_TOKEN = 'JWT_TOKEN';
  return: string;
  profile$: BehaviorSubject<User>;

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.token = this.getJwtToken();
    this.decodedToken$ = new BehaviorSubject<any>(this.decodedToken);
    this.profile$ = new BehaviorSubject<any>([]);
    this.decodedToken = this.getDecodedToken();
    this.getDashboardRolerRouting().then((value) => {
      this.return = this.route.snapshot.queryParams['return'] || value;
    });
  }

  public signin(jsonPayload: any) {
    const { email, password } = jsonPayload;

    this.signingIn = true;
    return this.http.post(`/login_check`, { email, password }).pipe(
      map((res: any) => {
        this.signingIn = false;
        this.setUserAndToken(res.token, !!res);
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public forgotPassword(jsonPayload: any) {
    return this.http.post(`/reset-password`, { email: jsonPayload }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public passwordReset(token: string, jsonPayload: any) {
    return this.http
      .post(`/reset-password/reset/${token}`, { plainPassword: { first: jsonPayload, second: jsonPayload } })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public register(jsonPayload: any) {
    const { email, firstName, lastName, dateOfBirth, password, confirmPassword, userType, agreed } = jsonPayload;
    jsonPayload.dateOfBirth = _moment(dateOfBirth).format('DD/MM/YYYY');
    delete jsonPayload.agreed;
    delete jsonPayload.userType;
    delete jsonPayload.confirmPassword;

    return this.http.post(`/register`, jsonPayload).pipe(
      map((res: any) => {
        this.signingIn = true;
        this.setUserAndToken(res.token, false);
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getDataFromToken(token) {
    const _decodeToken = (decodedToken) => {
      try {
        if (decodedToken) {
          return JSON.parse(atob(decodedToken));
        }
      } catch {
        return;
      }
    };

    const decoded = token
      .split('.')
      .map((splitToken) => _decodeToken(splitToken))
      .reduce((acc, curr) => {
        if (curr) {
          acc = { ...acc, ...curr };
        }
        return acc;
      }, Object.create(null));

    return Object.keys(decoded).length === 0 ? undefined : decoded;
  }


  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */
    this.userService.getProfile().subscribe({
      next: (result) => {
        this.setUserAndToken(this.getJwtToken());
        this.profile$.next(result);
      },
      error: () => {
        this.signOut();
      }
    }
    );
  }

  signOut() {
    this.token = undefined;
    this.decodedToken = undefined;
    this.cookieService.delete(this.JWT_TOKEN);
    this.router.navigateByUrl('login');
  }

  isLoggedIn(): boolean {
    const token = this.getJwtToken();
    return !!token && !this.isTokenExpired(token);
  }

  getJwtToken() {
    return this.cookieService.get(this.JWT_TOKEN);
  }

  getDecodedToken(): any {
    return this.getDataFromToken(this.getJwtToken());
  }

  isTokenExpired(token: string): boolean {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiry = payload.exp;
    const now = Math.floor(Date.now() / 1000);

    return now > expiry;
  }

  async setUserAndToken(token: string, isAuthenticated: boolean = false) {
    this.signingIn = isAuthenticated;

    if (this.getJwtToken() !== undefined) {
      this.cookieService.deleteAll();
    }

    this.token = token;
    this.decodedToken = this.getDataFromToken(token);
    this.decodedToken$.next(this.getDataFromToken(token));
    this.cookieService.set(this.JWT_TOKEN, token, this.decodedToken.exp, '', '', true, 'Strict');

    if (isAuthenticated) {
      this.return = this.route.snapshot.queryParams['return'] || await this.getDashboardRolerRouting();
      this.router.navigate([this.return]);
    }
  }

  async getDashboardRolerRouting(): Promise<string> {
    const decodedToken = await this.getDataFromToken(this.getJwtToken());
    if (!decodedToken || Object.keys(decodedToken).length === 0) {
      return 'login';
    }

    for (let role in decodedToken.roles) {
      if (decodedToken['roles'][role].hasOwnProperty(role)) {
        role = decodedToken['roles'][role];
        switch (role) {
          case 'ROLE_ADMIN':
          case 'ROLE_SUPER_ADMIN':
            return 'dashboard/admin';
          case 'ROLE_ORGANISER':
            return 'dashboard/organiser';
          case 'ROLE_OFFICIAL':
            return 'dashboard/official';
          case 'ROLE_RIDER':
            return 'dashboard/rider';
          case 'ROLE_SPECTATOR':
          default:
            return 'shop';
        }
      }
    }
  }
}
