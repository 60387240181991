import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {
  private config: { remote: string, remoteNonApi: string, portApi: string, production: boolean, headerName: string, authScheme: string, throwNoTokenError: boolean, skipWhenExpired: boolean } = {
    remote: environment.apiURL + '/api',
    remoteNonApi: environment.apiURL,
    portApi: environment.portApi,
    production: environment.production,
    headerName: 'Authorization',
    authScheme: 'Bearer ',
    throwNoTokenError: false,
    skipWhenExpired: false,
  };

  public getConfig(): { remote: string, remoteNonApi: string, portApi: string, production: boolean, headerName: string, authScheme: string, throwNoTokenError: boolean, skipWhenExpired: boolean } {
    return this.config;
  }
}
