import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';

import { filter } from 'rxjs/operators';
import { UILibIconService } from './shared/services/ui-lib-icon.service';
import { LayoutService } from './shared/services/layout.service';
import { ThemeService } from './shared/services/theme.service';
import { AppConfig } from './app.config';
import { MessagingService } from './shared/services/api/messaging.service';
import { JwtAuthService } from './shared/services/api/auth/jwt-auth.service';
import { MatSnackbarComponent } from './shared/components/mat-snackbar/mat-snackbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Event Suite';
  pageTitle = '';

  constructor(
    private appConfig: AppConfig,
    public title: Title,
    private router: Router,
    private route: ActivatedRoute,
    public themeService: ThemeService,
    private layoutService: LayoutService,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private messagingService: MessagingService,
    private jwtAuth: JwtAuthService,
    private _snack: MatSnackbarComponent
  ) {
    this.iconService.init();
  }

  async ngOnInit() {
    await this.messagingService.init();
    this.changePageTitle();
    this.themeService.applyMatTheme(this.layoutService.layoutConf.matTheme);
  }

  ngAfterViewInit() {
    if (this.appConfig.getConfig().production) {
      this.loadGoogleAnalytics();
    }
  }

  private loadGoogleAnalytics() {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-SXJXCS5ZMV';

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-SXJXCS5ZMV');
    `;

    document.head.appendChild(script1);
    document.head.appendChild(script2);
  }

  changePageTitle() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const currentUrl = routeChange.url;
      const token = this.jwtAuth.getJwtToken();
      if ((!token || this.jwtAuth.isTokenExpired(token)) && !currentUrl.includes('login') && !currentUrl.includes('register') && currentUrl[0] !== '/') {
        this.router.navigate(['login'], {
          queryParams: {
            return: currentUrl,
          },
        }).then(() => this._snack.open('Your previous session has expired please login once again'));
      }
      const routeParts = this.routePartsService.generateRouteParts(this.route.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
